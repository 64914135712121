import { gql } from 'graphql-request';
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import { ParsedUrlQuery } from 'querystring';

import MaxWidthContainer from '@/components/dom/max-width-container';
import { Heading } from '@/components/dom/text-elements';
import { TeamAvatar, UserAvatar } from '@/components/global/avatar';
import { useCurrentOrganization } from '@/components/global/current-organization';
import Icon from '@/components/global/icon';
import ReadMore from '@/components/global/read-more';
import { IndexCard } from '@/components/products/index-card';
import { FormatUtcDate } from '@/helpers/dates';
import cn from '@/lib/cn';
import { SITE_NAME } from '@/lib/constants';
import { BUILD_SERVER_SIDE_PROPS_GQL_CLIENT } from '@/lib/graphql';
import { INDEX_CARD_FRAGMENT, INDEX_CARD_FRAGMENT_NAME, IndexCardType } from '@/queries/index-card';

type UserCardType = {
    id: string;
    name: string;
    avatarUrl: Optional<string>;
};

type OrganizationProps = {
    id: string;
    name: string;
    description: string;
    avatarUrl: Optional<string>;
    aboutUrl: string;
    twitterHandle: string;
    publicIndexes: Array<IndexCardType>;
    members: Array<UserCardType>;
};

const ORGANIZATION_QUERY = gql`
    ${INDEX_CARD_FRAGMENT}
    query getOrganization($id: ID!, $threeMonthsAgo: Date!) {
        organization(id: $id) {
            id
            name
            description
            avatarUrl
            aboutUrl
            twitterHandle
            publicIndexes {
                ...${INDEX_CARD_FRAGMENT_NAME}
            }
            members {
                id
                name
                avatarUrl
            }
        }
    }
`;

const Organization = (props: OrganizationProps) => {
    const { name, description, avatarUrl, aboutUrl, twitterHandle, members, publicIndexes } = props;
    const titleText = `${name} | ${SITE_NAME}`;

    const { currentOrganizationProfileUrl } = useCurrentOrganization();

    const headerMemberAvatars = (
        <>
            {members.slice(0, members.length === 4 ? 4 : 3).map((user) => {
                return (
                    <UserAvatar
                        key={`header-avatar-${user.id}`}
                        url={user.avatarUrl}
                        name={user.name}
                        className="h-8 w-8 text-xl"
                        linkProps={{
                            className: 'mr-2',
                            href: `/user/${user.id}`,
                            title: `${user.name} Profile Page`,
                        }}
                    />
                );
            })}
            {members.length > 4 && (
                <div
                    className={cn(
                        'bg-brand-unicorn text-white mb-3 flex items-center justify-center bg-center',
                        'h-8 w-8 text-xl'
                    )}
                >
                    <span>+{members.length - 3}</span>
                </div>
            )}
        </>
    );

    return (
        <div>
            <Head>
                <title>{titleText}</title>
                <meta
                    property="og:image"
                    content="https://www.gothematic.com/favicon/favicon-32x32.png"
                />
            </Head>

            <div className="flex flex-col">
                <div className="mx-auto w-full bg-brand-gray flex bg-gradient-to-b from-brand-gray-light to-brand-gray p-4">
                    <MaxWidthContainer className="w-full flex flex-col md:flex-row justify-between gap-9 items-start my-8">
                        <TeamAvatar
                            url={avatarUrl}
                            name={name}
                            className="h-48 w-48 text-9xl"
                        />
                        <div className="flex flex-col basis-2/3">
                            <Heading
                                importance={6}
                                className="text-brand-blue-nu"
                            >
                                TEAM
                            </Heading>
                            <Heading importance={2}>{name}</Heading>

                            {description && (
                                <>
                                    <ReadMore
                                        text={description}
                                        lines={3}
                                        className="text-sm mt-4"
                                        showMoreLessClassName="text-sm"
                                    />

                                    <Link
                                        href={currentOrganizationProfileUrl}
                                        className="flex items-center no-underline text-brand-blue-yves hover:text-brand-blue-nu visited:text-brand-blue-yves transition-all font-brand-bold mt-4"
                                        title="Edit Team Profile"
                                    >
                                        Edit Team Profile
                                        {/*
                                            TODO: Move h-full into Icon component
                                                  in separate effort as it may
                                                  break Icon styling elsewhere
                                        */}
                                        <Icon
                                            type="caret"
                                            rotate={180}
                                            color="blueYves"
                                            size="xs"
                                            className="ml-1"
                                            iconClassName="h-full"
                                        />
                                    </Link>
                                </>
                            )}
                        </div>
                        <div className="flex flex-col justify-between gap-2 h-full">
                            <div>
                                {aboutUrl ? (
                                    <div className="mb-4 md:mb-0 flex flex-col gap-3">
                                        <Link
                                            className="group no-underline text-brand-blue-yves
                                            hover:text-brand-blue-nu
                                            visited:text-brand-blue-yves font-brand-bold text-sm relative truncate flex items-center"
                                            href={aboutUrl}
                                            title={aboutUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <Icon
                                                type="openExternal"
                                                color="blueYves"
                                                groupHoverColor="blueNu"
                                                className="mr-2"
                                            />
                                            Website
                                        </Link>
                                    </div>
                                ) : null}
                                {twitterHandle ? (
                                    <div>
                                        <a
                                            className="no-underline text-brand-blue-yves visited:text-brand-blue-yves font-brand-bold text-sm"
                                            href={'https://twitter.com/' + twitterHandle}
                                            title={twitterHandle}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <Icon
                                                type="twitter"
                                                color="blueYves"
                                                className="mr-2 pt-1"
                                            />
                                            Twitter
                                        </a>
                                    </div>
                                ) : null}
                            </div>
                            <div className="flex flex-col gap-3">
                                <span className="font-brand-bold">
                                    {members.length} {members.length === 1 ? 'Member' : 'Members'}
                                </span>
                                <div className="flex">{headerMemberAvatars}</div>
                            </div>
                        </div>
                    </MaxWidthContainer>
                </div>
                <MaxWidthContainer className="w-full my-8 flex flex-col justify-between">
                    <div className="flex items-center gap-2">
                        <Heading importance={4}>Indices Created</Heading>
                        <span className="text-brand-gray-darker">({publicIndexes.length})</span>
                    </div>
                    <div className="mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 grid-flow-row-dense gap-4 bg-gradient-to-b from-brand-gray-light to-brand-gray p-4 mt-4">
                        {publicIndexes.map((index) => {
                            return (
                                <IndexCard
                                    key={`index-card-${index.id}`}
                                    index={index}
                                />
                            );
                        })}
                    </div>
                </MaxWidthContainer>
            </div>
        </div>
    );
};

// export index page
export default Organization;

// static build code is found below
interface Params extends ParsedUrlQuery {
    id: string;
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    const { id } = context.params as Params;

    // Three months ago
    const performanceStartDate = new Date();
    performanceStartDate.setMonth(performanceStartDate.getMonth() - 3);

    const client = BUILD_SERVER_SIDE_PROPS_GQL_CLIENT(context.req.headers);
    const data: { organization: Optional<OrganizationProps> } = await client.request(ORGANIZATION_QUERY, {
        id: id && id.toUpperCase(),
        threeMonthsAgo: FormatUtcDate(performanceStartDate, 'yyyy-MM-dd'),
    });
    const { organization } = data;

    if (!organization) {
        return { notFound: true };
    }

    return { props: organization };
};
