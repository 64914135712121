import BaseModal from '@/components/global/base-modal';
import Button from '@/components/global/button';

type ConfirmModalProps = {
    message: string;
    isOpen: boolean;
    onConfirm: () => void;
    onRequestClose: () => void;
    confirmText?: string;
};

const ConfirmModal = ({ message, isOpen, onConfirm, onRequestClose, confirmText = 'Confirm' }: ConfirmModalProps) => {
    return (
        <BaseModal
            header={message}
            allowClose={false}
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            size="1/3"
        >
            <div className="flex justify-center p-6">
                <Button
                    type="action"
                    onClick={onConfirm}
                    color="primary"
                    className="px-2 mr-4 font-brand-bold"
                    roundedCorners="md"
                >
                    {confirmText}
                </Button>
                <Button
                    type="action"
                    onClick={onRequestClose}
                    color="transparent"
                    className="px-2 mr-4 color !text-brand-blue-nu border border-brand-blue-nu font-brand-bold"
                    roundedCorners="md"
                >
                    Cancel
                </Button>
            </div>
        </BaseModal>
    );
};

export default ConfirmModal;
